import styled from '@emotion/styled'
import { TimedRenderer, TimeUtil, useFeatureFlags } from 'cuenect-web-core'
import { navigate } from 'gatsby'
import parse from 'html-react-parser'
import i18n from 'i18next'
import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ContentContainer } from '../components'
import {
  BigCountdown,
  Button,
  ContentColumn,
  Heading,
  KeyVisual,
  LineContainer,
  Paragraph,
} from '../components'
import { Cognisphere } from '../components/molecules/cognisphere/cognisphere'
import { PageFrame, PageParams } from '../components/organisms/pageFrame'
import { mq, Spacer, useGlobalContext, useVodContext } from './../utility'

import { eventConfig, isPostEvent } from './../config'

//Webcast 01
const WEBINAR_01_ATTEND_CTA = '2021-10-06T12:59:59Z'
const WEBINAR_01_VOD_CTA = '2021-10-06T15:00Z'

// Webcast 02
const WEBCAST_02_START = '2021-10-20T13:00Z'
const HIDE_COUNTDOWN_TO_WEBCAST_2_TIME = WEBCAST_02_START
const SHOW_WEBINAR_02_VOD_CTA = '2021-10-20T15:00Z'

// Webcast 03
const SHOW_COUNTDOWN_TO_WEBCAST_3_TIME = '2021-10-20T14:00Z'
const END_COUNTDOWN_TO_WEBCAST_3_TIME = '2021-11-03T14:00Z'

// Webcast 03
const SHOW_COUNTDOWN_TO_WEBCAST_4_TIME = '2021-11-03T15:00Z'
const END_COUNTDOWN_TO_WEBCAST_4_TIME = '2021-11-17T14:00Z'

const IndexPage = (params: PageParams) => {
  const {
    pageContext: { lang },
  } = params
  const { dispatch: openVod } = useVodContext()
  const {
    state: { status: gobalStatus },
  } = useGlobalContext()
  const [loaded, setLoaded] = React.useState(false)
  const [countdownCompleted, setCountdownCompleted] = React.useState(false)
  const { phase2, phase3 } = useFeatureFlags()
  const postEvent = isPostEvent()
  const { currentWebinar } = eventConfig

  if (lang === '') {
    if (typeof window !== 'undefined') {
      navigate(`/en${document.location.search}`)
    }

    return <></>
  }

  useEffect(() => {
    i18n.changeLanguage(lang)
    setLoaded(true)
  }, [])

  const { t } = useTranslation('index')

  const now = TimeUtil.getNowUtc()
  const showVodCtaWebcast01 = now.isAfter(WEBINAR_01_VOD_CTA)

  const countdownCount = now.isAfter(WEBINAR_01_ATTEND_CTA) ? 2 : 1

  const isHideCountDownHour =
    now.isAfter(WEBINAR_01_ATTEND_CTA) && now.isBefore('2021-10-06T14:00Z')

  console.log({
    isAfter: now.isAfter(WEBINAR_01_ATTEND_CTA),
    isBefore: now.isBefore('2021-10-06T14:00:Z'),
  })

  return (
    <PageFrame {...params} pageName="index" isTop>
      {loaded && (
        <>
          <KeyVisual>
            <Cognisphere visual="/assets/keyvisual.mp4" fullscreen />

            <ContentContainer fullheight>
              <StartTeaser fullheight width={50}>
                <LineContainer className="LineContainer">
                  <Heading>{parse(t('header'))}</Heading>
                </LineContainer>
              </StartTeaser>
            </ContentContainer>
          </KeyVisual>

          <Spacer />

          {/* this is the legacy way to time the countdown, please use TimedRenderer instead as shown... */}
          <TimedRenderer endUtc={HIDE_COUNTDOWN_TO_WEBCAST_2_TIME}>
            {!countdownCompleted && !isHideCountDownHour && (
              <>
                <ContentContainer>
                  <BigCountdown
                    headline={
                      <Heading type="h4">
                        <Trans
                          i18nKey="counter.header"
                          t={t}
                          count={countdownCount}
                        >
                          Webcast {{ currentWebinar }} starts in
                        </Trans>
                      </Heading>
                    }
                  />
                </ContentContainer>
                <Spacer />
              </>
            )}
          </TimedRenderer>

          {/* ...here */}
          <TimedRenderer
            startUtc={SHOW_COUNTDOWN_TO_WEBCAST_3_TIME}
            endUtc={END_COUNTDOWN_TO_WEBCAST_3_TIME}
          >
            <BigCountdown
              headline={
                <Heading type="h4">
                  <Trans i18nKey="counter.header" t={t} count={3}>
                    Webcast {{ currentWebinar }} starts in
                  </Trans>
                </Heading>
              }
              end={TimeUtil.getUtc(END_COUNTDOWN_TO_WEBCAST_3_TIME)}
              start={TimeUtil.getUtc(SHOW_COUNTDOWN_TO_WEBCAST_3_TIME)}
            />
            <Spacer />
          </TimedRenderer>

          <TimedRenderer
            startUtc={SHOW_COUNTDOWN_TO_WEBCAST_4_TIME}
            endUtc={END_COUNTDOWN_TO_WEBCAST_4_TIME}
          >
            <BigCountdown
              headline={
                <Heading type="h4">
                  <Trans i18nKey="counter.header" t={t} count={4}>
                    Webcast {{ currentWebinar }} starts in
                  </Trans>
                </Heading>
              }
              end={TimeUtil.getUtc(END_COUNTDOWN_TO_WEBCAST_4_TIME)}
              start={TimeUtil.getUtc(SHOW_COUNTDOWN_TO_WEBCAST_4_TIME)}
            />
            <Spacer />
          </TimedRenderer>

          <ContentContainer align="center">
            <StartTeaser width={66}>
              <LineContainer className="LineContainer">
                <Heading type="h6" spacing={{ bottom: 0.8 }}>
                  {t('teaser.topline')}
                </Heading>
                <Heading type="h2">{parse(t(`teaser.header`))}</Heading>
              </LineContainer>
              <Paragraph>
                {parse(t(`teaser.copy${postEvent ? 'PostEvent' : ''}`))}
              </Paragraph>
            </StartTeaser>
          </ContentContainer>
          <Spacer />
          <ContentContainer>
            <ContentColumn
              left={<img src={t('row1.visual')} />}
              right={
                <div>
                  <Heading type="h6" spacing={{ bottom: 0.8 }}>
                    {t('row1.topline')}
                  </Heading>
                  <Heading type="h4">{parse(t('row1.header'))}</Heading>
                  <Paragraph>
                    {parse(t(`row1.copy${postEvent ? 'PostEvent' : ''}`))}
                  </Paragraph>
                  {!showVodCtaWebcast01 && (
                    <Button
                      onClick={() => navigate(`/${lang}/program/webcast1`)}
                    >
                      {t(`cta.attendLabel`)}
                    </Button>
                  )}
                  {showVodCtaWebcast01 && (
                    <Button
                      onClick={() => navigate(`/${lang}/program/webcast1`)}
                      secondary
                    >
                      {t(`cta.vodLabel`)}
                    </Button>
                  )}
                </div>
              }
            />
          </ContentContainer>
          <Spacer />
          <ContentContainer>
            <ContentColumn
              revertMobile
              right={<img src={t('row2.visual')} />}
              left={
                <div>
                  {' '}
                  <Heading type="h6" spacing={{ bottom: 0.8 }}>
                    {t('row2.topline')}
                  </Heading>
                  <Heading type="h4">{parse(t('row2.header'))}</Heading>
                  <Paragraph>
                    {parse(t(`row2.copy${postEvent ? 'PostEvent' : ''}`))}
                  </Paragraph>
                  <TimedRenderer endUtc={SHOW_WEBINAR_02_VOD_CTA}>
                    <Button
                      onClick={() => navigate(`/${lang}/program-webcast2`)}
                    >
                      {t(`cta.attendLabel`)}
                    </Button>
                  </TimedRenderer>
                  <TimedRenderer startUtc={SHOW_WEBINAR_02_VOD_CTA}>
                    <Button
                      onClick={() => navigate(`/${lang}/program-webcast2`)}
                      secondary
                    >
                      {t(`cta.vodLabel`)}
                    </Button>
                  </TimedRenderer>
                </div>
              }
            />
          </ContentContainer>
          <Spacer />
          <ContentContainer>
            <ContentColumn
              left={<img src={t('row3.visual')} />}
              right={
                <div>
                  {' '}
                  <Heading type="h6" spacing={{ bottom: 0.8 }}>
                    {t('row3.topline')}
                  </Heading>
                  <Heading type="h4">{parse(t('row3.header'))}</Heading>
                  <Paragraph>
                    {parse(t(`row3.copy${postEvent ? 'PostEvent' : ''}`))}
                  </Paragraph>
                  <TimedRenderer endUtc="2021-11-03T06:00Z">
                    <Button
                      onClick={() => navigate(`/${lang}/program-webcast3`)}
                      secondary
                    >
                      {t(`cta.agendaLabel`)}
                    </Button>
                  </TimedRenderer>
                  <TimedRenderer
                    startUtc="2021-11-03T06:00Z"
                    endUtc="2021-11-03T16:00Z"
                  >
                    <Button
                      onClick={() => navigate(`/${lang}/program-webcast3`)}
                    >
                      {t(`cta.attendLabel`)}
                    </Button>
                  </TimedRenderer>
                  <TimedRenderer startUtc="2021-11-03T16:00Z">
                    <Button
                      onClick={() => navigate(`/${lang}/program-webcast3`)}
                      secondary
                    >
                      {t(`cta.vodLabel`)}
                    </Button>
                  </TimedRenderer>
                </div>
              }
            />
          </ContentContainer>
          <Spacer />
          <ContentContainer>
            <ContentColumn
              revertMobile
              right={<img src={t('row4.visual')} />}
              left={
                <div>
                  {' '}
                  <Heading type="h6" spacing={{ bottom: 0.8 }}>
                    {t('row4.topline')}
                  </Heading>
                  <Heading type="h4">{parse(t('row4.header'))}</Heading>
                  <Paragraph>
                    {parse(t(`row4.copy${postEvent ? 'PostEvent' : ''}`))}
                  </Paragraph>
                  <TimedRenderer endUtc="2021-11-17T06:00Z">
                    <Button
                      onClick={() => navigate(`/${lang}/program-webcast4`)}
                      secondary
                    >
                      {t(`cta.agendaLabel`)}
                    </Button>
                  </TimedRenderer>
                  <TimedRenderer
                    startUtc="2021-11-17T06:00Z"
                    endUtc="2021-11-17T16:00Z"
                  >
                    <Button
                      onClick={() => navigate(`/${lang}/program-webcast4`)}
                    >
                      {t(`cta.attendLabel`)}
                    </Button>
                  </TimedRenderer>
                  <TimedRenderer startUtc="2021-11-17T16:00Z">
                    <Button
                      onClick={() => navigate(`/${lang}/program-webcast4`)}
                      secondary
                    >
                      {t(`cta.vodLabel`)}
                    </Button>
                  </TimedRenderer>
                </div>
              }
            />
          </ContentContainer>
          <Spacer />
        </>
      )}
    </PageFrame>
  )
}

export default IndexPage

interface StartTeaserProps {
  fullheight?: boolean
  width?: number
}

const StartTeaser = styled.div<StartTeaserProps>(
  ({ theme: { grid }, fullheight, width }) => `
  height: 100%;
  ${[mq[3]]}{
    ${width && ` width: ${width}%;`}
  }
  ${
    fullheight &&
    `min-height:calc(100vh - 220px);
    margin-top:100px;
    ${[mq[2]]}{
      margin-top:0px;
      display:flex;
      align-items:center;
    }`
  }

  margin-left:${100 / grid.columns}vw;
  h1{
    margin:0;
  }
  p, .LineContainer{ margin-bottom:50px;}
`
)
